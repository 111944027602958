import { environment } from '../../environments/environment';

type Environment = 'DEV' | 'QA' | 'STAGE' | 'PROD';

function isValidEnvironment(value: string | undefined): value is Environment {
  if (value == undefined) {
    return false;
  }
  return ['DEV', 'QA', 'STAGE', 'PROD'].includes(value);
}

const isValid = isValidEnvironment(environment.ENVIRONMENT);
const activeEnvironment: Environment = isValid
  ? (environment.ENVIRONMENT as Environment) || 'DEV'
  : 'DEV';

export const currentKeySystem = environment.LOCATION;

const SETTINGS: GlobalSettings = {
  MEX: {
    keySystemIdentifier: 'México',
    countryGroup: [
      {
        code: 'MEX',
        countryCode: 'MX',
        name: 'México',
        icon: '/assets/images/svg/vacancy/mex-icon.svg',
        currency: 'Pesos mexicanos',
        currencyIcon: '/assets/icons/generic-currency.svg',
        billingSettings: {
          paidCurrency: 'MXN',
          automaticBilling: false,
          taxId: true,
          reminderCheck: true,
          taxPlaceholder: 'DEMO001206IW5',
          taxIdName: 'RFC',
          placeholder: 'DEMO001206IW5',
          taxIdPattern: new RegExp(
            /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/
          ),
          taxIdMaxLength: 13,
          taxes: 1.16,
          multiplePayMethods: true,
          minimumAmount: 10,
        },
      },
      {
        code: 'ESP',
        countryCode: 'ES',
        name: 'España',
        icon: '/assets/images/svg/vacancy/spain-flag-icon.svg',
        currency: 'Euro',
        currencyIcon: '/assets/icons/euro-icon.svg',
        billingSettings: {
          paidCurrency: 'EUR',
          automaticBilling: true,
          taxId: true,
          reminderCheck: false,
          placeholder: '12345678A',
          taxIdName: 'NIF',
          taxIdPattern: new RegExp(
            /^(([A-W])(\d{7})([0-9A-J]))|(\d{8}[A-Z])|([XYZ]\d{7}[A-Z])$/
          ),
          // * Requirement: https://linear.app/involverh/issue/INV-3327
          // * Based on: https://gist.github.com/afgomez/5691823
          taxIdMaxLength: 9,
          taxes: 1.21,
          multiplePayMethods: true,
          minimumAmount: 0.5,
        },
      },
      {
        code: 'BRA',
        countryCode: 'BR',
        name: 'Brasil',
        icon: '/assets/images/svg/vacancy/brazil-flag-icon.svg',
        currency: 'Real brasileño',
        currencyIcon: '/assets/icons/generic-currency.svg',
        billingSettings: {
          paidCurrency: 'USD',
          automaticBilling: true,
          taxId: false,
          reminderCheck: false,
          taxIdName: 'GENERIC',
          placeholder: '',
          taxIdPattern: new RegExp(
            /^(([A-W])(\d{7})([0-9A-J]))|(\d{8}[A-Z])|([XYZ]\d{7}[A-Z])$/
          ),
          // * Requirement: https://linear.app/involverh/issue/INV-3327
          // * Based on: https://gist.github.com/afgomez/5691823
          taxIdMaxLength: 9,
          taxes: 1.21,
          multiplePayMethods: true,
          minimumAmount: 10,
        },
      },
      {
        code: 'LAT',
        countryCode: 'CO',
        name: 'Colombia',
        icon: '/assets/images/svg/vacancy/colombia-flag-icon.svg',
        currency: 'Peso colombiano',
        currencyIcon: '/assets/icons/generic-currency.svg',
        billingSettings: {
          paidCurrency: 'USD',
          automaticBilling: true,
          taxId: false,
          reminderCheck: false,
          taxIdName: 'GENERIC',
          placeholder: '',
          taxIdPattern: new RegExp(
            /^(([A-W])(\d{7})([0-9A-J]))|(\d{8}[A-Z])|([XYZ]\d{7}[A-Z])$/
          ),
          // * Requirement: https://linear.app/involverh/issue/INV-3327
          // * Based on: https://gist.github.com/afgomez/5691823
          taxIdMaxLength: 9,
          taxes: 1.21,
          multiplePayMethods: true,
          minimumAmount: 10,
        },
      },
    ],
    currencyList: [
      {
        code: 'USA',
        countryCode: 'MXN',
        currency: 'Pesos mexicanos',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      {
        code: 'ESP',
        countryCode: 'EUR',
        currency: 'ESP',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      {
        code: 'COL',
        countryCode: 'COP',
        currency: 'Peso colombiano',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      {
        code: 'USA',
        countryCode: 'USD',
        currency: 'Dólar',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      {
        code: 'BRA',
        countryCode: 'BRL',
        currency: 'Real brasileño',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
    ],
    paymentList: {
      ESP: {
        code: 'ESP',
        countryCode: 'ESP',
        icon: '/assets/images/svg/vacancy/spain-flag-icon.svg',
        currency: 'Euro',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      COL: {
        code: 'COL',
        countryCode: 'USA',
        icon: '/assets/images/svg/vacancy/colombia-flag-icon.svg',
        currency: 'Dólar',
        currencyIcon: '/assets/icons/generic-currency.svg',
      },
    },
    maxBenefitsPerColumnInDesktopView: 14,
    minEducationLevelToShowAdditionalFields: 3,
    minEducationLevelToAutocompleteInstitution: 5,
    isSectorVisible: true,
    hasMultipleSalaryPeriodicity: true,
    educationStatusInLowerEducation: [1, 2, 3, 5],
    educationStatusInHigherEducation: [1, 2, 3, 4, 5, 6],
    showsCandidateSalaryNormalized: false,
    isChatGptEnabled: true,
    phoneFormaterPattern: new RegExp(/(\d{0,2})(\d{0,4})(\d{0,4})/),
    phoneValidatorPattern: new RegExp(/\d{2} \d{4} \d{4}/),
    minimumAmount: 10,
    salesEmail: 'comercial@involverh.com',
    isInMaintenance: false,
    momentLocale: 'es',
    phoneLada: '+52',
    phoneRequired: true,
    termsAndConditionsUrl: 'https://www.involverh.com/terminos-y-condiciones',
    privacyNoticeUrl: 'https://www.involverh.com/aviso-de-privacidad',
    cookiesUrl: 'https://www.involverh.com/politica-de-cookies',
    tips: 'https://www.involverh.com/tooltips',
    mainLanding: 'https://www.involverh.com/',
    chatGptEnabled: true,
  },
  ESP: {
    keySystemIdentifier: 'España',
    countryGroup: [
      {
        code: 'MEX',
        countryCode: 'MX',
        name: 'México',
        icon: '/assets/images/svg/vacancy/mex-icon.svg',
        currency: 'Pesos mexicanos',
        currencyIcon: '/assets/icons/generic-currency.svg',
        billingSettings: {
          paidCurrency: 'MXN',
          automaticBilling: false,
          taxId: true,
          reminderCheck: true,
          taxPlaceholder: 'DEMO001206IW5',
          taxIdName: 'RFC',
          placeholder: 'DEMO001206IW5',
          taxIdPattern: new RegExp(
            /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/
          ),
          taxIdMaxLength: 13,
          taxes: 1.16,
          multiplePayMethods: true,
          minimumAmount: 10,
        },
      },
      {
        code: 'ESP',
        countryCode: 'ES',
        name: 'España',
        icon: '/assets/images/svg/vacancy/spain-flag-icon.svg',
        currency: 'Euro',
        currencyIcon: '/assets/icons/euro-icon.svg',
        billingSettings: {
          paidCurrency: 'EUR',
          automaticBilling: true,
          taxId: true,
          reminderCheck: false,
          placeholder: '12345678A',
          taxIdName: 'NIF',
          taxIdPattern: new RegExp(
            /^(([A-W])(\d{7})([0-9A-J]))|(\d{8}[A-Z])|([XYZ]\d{7}[A-Z])$/
          ),
          // * Requirement: https://linear.app/involverh/issue/INV-3327
          // * Based on: https://gist.github.com/afgomez/5691823
          taxIdMaxLength: 9,
          taxes: 1.21,
          multiplePayMethods: true,
          minimumAmount: 0.5,
        },
      },
      {
        code: 'LAT',
        countryCode: 'CO',
        name: 'Colombia',
        icon: '/assets/images/svg/vacancy/colombia-flag-icon.svg',
        currency: 'Peso colombiano',
        currencyIcon: '/assets/icons/generic-currency.svg',
        billingSettings: {
          paidCurrency: 'USD',
          automaticBilling: true,
          taxId: false,
          reminderCheck: false,
          taxIdName: 'GENERIC',
          placeholder: '',
          taxIdPattern: new RegExp(
            /^(([A-W])(\d{7})([0-9A-J]))|(\d{8}[A-Z])|([XYZ]\d{7}[A-Z])$/
          ),
          // * Requirement: https://linear.app/involverh/issue/INV-3327
          // * Based on: https://gist.github.com/afgomez/5691823
          taxIdMaxLength: 9,
          taxes: 1.21,
          multiplePayMethods: true,
          minimumAmount: 10,
        },
      },
    ],
    currencyList: [
      {
        code: 'USA',
        countryCode: 'MXN',
        currency: 'Pesos mexicanos',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      {
        code: 'ESP',
        countryCode: 'EUR',
        currency: 'ESP',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      {
        code: 'COL',
        countryCode: 'COP',
        currency: 'Peso colombiano',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      {
        code: 'USA',
        countryCode: 'USD',
        currency: 'Dólar',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      {
        code: 'BRA',
        countryCode: 'BRL',
        currency: 'Real brasileño',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
    ],
    paymentList: {
      ESP: {
        code: 'ESP',
        countryCode: 'ESP',
        icon: '/assets/images/svg/vacancy/spain-flag-icon.svg',
        currency: 'Euro',
        currencyIcon: '/assets/icons/euro-icon.svg',
      },
      COL: {
        code: 'COL',
        countryCode: 'USA',
        icon: '/assets/images/svg/vacancy/colombia-flag-icon.svg',
        currency: 'Dólar',
        currencyIcon: '/assets/icons/generic-currency.svg',
      },
    },
    maxBenefitsPerColumnInDesktopView: 14,
    minEducationLevelToShowAdditionalFields: 3,
    minEducationLevelToAutocompleteInstitution: 5,
    isSectorVisible: true,
    hasMultipleSalaryPeriodicity: true,
    educationStatusInLowerEducation: [1, 2, 4],
    educationStatusInHigherEducation: [1, 2, 3, 4, 5, 6],
    showsCandidateSalaryNormalized: true,
    isChatGptEnabled: true,
    phoneFormaterPattern: new RegExp(/(\d{0,2})(\d{0,4})(\d{0,4})/),
    phoneValidatorPattern: new RegExp(/\d{2} \d{4} \d{4}/),
    minimumAmount: 0.5,
    salesEmail: 'comercial@involverh.es',
    isInMaintenance: false,
    momentLocale: 'es',
    phoneLada: '+34',
    phoneRequired: false,
    termsAndConditionsUrl: 'https://www.involverh.es/terminos-y-condiciones',
    privacyNoticeUrl: 'https://www.involverh.es/politica-de-privacidad',
    cookiesUrl: 'https://www.involverh.es/politica-de-cookies',
    tips: 'https://www.involverh.es/tooltips',
    mainLanding: 'https://www.involverh.es/',
    chatGptEnabled: true,
  },
};

const ENVIRONMENTS: IEnvironmentList = {
  MEX: {
    DEV: {
      production: false,
      restPrefix:
        'https://2as657ryeb.execute-api.us-east-1.amazonaws.com/dev/involve',
      restPrefixPay: 'http://54.221.13.60:8092/involve',
      restPsychometric:
        'https://p08ea3ctx2.execute-api.us-east-1.amazonaws.com/dev',
      jobPool: 'https://involvedevbolsa.involverh.com.mx/',
      staffPanelUrl: 'https://staffdev.involverh.com.mx/login',
      candidateUrl: 'https://involvedevcan.involverh.com.mx/',
      restPrefixChatBot:
        'https://an2se13cbj.execute-api.us-east-1.amazonaws.com/dev/chatbot-reclutador',
      chatBotToken:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMzI4Nzk1LjQzMDUyNzR9.Ku1pbXwKS9OPQx4Ysy7MxsWTuTqhXyj6-WjOF6CHUYk',
      firebase: {
        apiKey: 'AIzaSyDts4tJyQtJA7PRmyUXYcLr12m6nvuc5Gw',
        authDomain: 'involvedemo.firebaseapp.com',
        projectId: 'involvedemo',
        storageBucket: 'involvedemo.appspot.com',
        messagingSenderId: '492023559670',
        appId: '1:492023559670:web:479c2fb00a90ebda4303d7',
      },
      VAPID_KEY:
        'BH8REQWtD8Jg00JJNw5SXdUiJxNt6l90uKXFwA7V5_5fFlZVzXrBacblExgMl_b2YiYSWMWlpNTQMaecr0BqjfA',
      isDevelopment: true,
      talkAppId: 'tqksAfwX',
      stripePublicKey: {
        MEX: 'pk_test_51MTqAzJCQT8rhUScfg4lDFpqtnpQ0YCPw54byaELUz3xB1rvjnWF2vYs5GRv74n7TRQ8Su9qJIeQSFTh76S6Se8w00HbVAHmsw',
        ESP: '',
      },
      apiUrl: {
        auth: 'https://cubone.involverh.com/auth',
        vacancy: 'https://cubone.involverh.com/vacancy',
        management: 'https://cubone.involverh.com/management',
        user: 'https://cubone.involverh.com/user',
        psychometric: 'https://cubone.involverh.com/psychometric',
        files: 'https://cubone.involverh.com/files',
        ia: 'https://assistant.dev.ia.involverh.es',
        iaAssistant: 'https://assistant.dev.ia.involverh.es',
      },
      userflow: {
        key: 'ct_rxxgrmljufguzhtf2kwoczzg2m',
        dontForgetToBillYourPurchaseId: 'a94c0e70-52ea-4714-82d4-6c931bc66914',
        billingReminderAfterLogin: 'eaace7cb-a7ff-4991-9571-d703e2125ff9',
      },
    },
    QA: {
      production: false,
      restPrefix:
        'https://2as657ryeb.execute-api.us-east-1.amazonaws.com/pre/involve',
      restPrefixPay:
        'https://2as657ryeb.execute-api.us-east-1.amazonaws.com/pre/pay',
      restPsychometric:
        'https://p08ea3ctx2.execute-api.us-east-1.amazonaws.com/pre',
      jobPool: 'https://involveprebolsa.involverh.com.mx/',
      staffPanelUrl: 'https://staffpre.involverh.com.mx/login',
      candidateUrl: 'https://involveprecan.involverh.com.mx/',
      restPrefixChatBot:
        'https://an2se13cbj.execute-api.us-east-1.amazonaws.com/pre/chatbot-reclutador',
      chatBotToken:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMzI4ODU5LjkyMjk4NjN9.YSTvrAPQffY7IeStoWdaWczDkqz4KRpCmnxFKtbPMJY',
      firebase: {
        apiKey: 'AIzaSyDts4tJyQtJA7PRmyUXYcLr12m6nvuc5Gw',
        authDomain: 'involvedemo.firebaseapp.com',
        projectId: 'involvedemo',
        storageBucket: 'involvedemo.appspot.com',
        messagingSenderId: '492023559670',
        appId: '1:492023559670:web:479c2fb00a90ebda4303d7',
      },
      VAPID_KEY:
        'BH8REQWtD8Jg00JJNw5SXdUiJxNt6l90uKXFwA7V5_5fFlZVzXrBacblExgMl_b2YiYSWMWlpNTQMaecr0BqjfA',
      isDevelopment: true,
      talkAppId: 'tqksAfwX',
      stripePublicKey: {
        MEX: 'pk_test_51MTqAzJCQT8rhUScfg4lDFpqtnpQ0YCPw54byaELUz3xB1rvjnWF2vYs5GRv74n7TRQ8Su9qJIeQSFTh76S6Se8w00HbVAHmsw',
        ESP: '',
      },
      apiUrl: {
        auth: 'https://kadabra.involverh.com/auth',
        vacancy: 'https://kadabra.involverh.com/vacancy',
        management: 'https://kadabra.involverh.com/management',
        user: 'https://kadabra.involverh.com/user',
        psychometric: 'https://kadabra.involverh.com/psychometric',
        files: 'https://kadabra.involverh.com/files',
        ia: 'https://amq3qsjt64.execute-api.us-east-1.amazonaws.com/qa',
        iaAssistant: 'https://assistant.qa.ia.involverh.es',
      },
      userflow: {
        key: 'ct_rxxgrmljufguzhtf2kwoczzg2m',
        dontForgetToBillYourPurchaseId: 'a94c0e70-52ea-4714-82d4-6c931bc66914',
        billingReminderAfterLogin: 'eaace7cb-a7ff-4991-9571-d703e2125ff9',
      },
    },
    STAGE: {
      production: true,
      restPrefix:
        'https://2as657ryeb.execute-api.us-east-1.amazonaws.com/pre/involve',
      restPrefixPay:
        'https://2as657ryeb.execute-api.us-east-1.amazonaws.com/pre/pay',
      restPsychometric:
        'https://p08ea3ctx2.execute-api.us-east-1.amazonaws.com/pre',
      jobPool: 'https://involvestagebolsa.involverh.com.mx/',
      staffPanelUrl: 'https://staffstage.involverh.com.mx/login',
      candidateUrl: 'https://involvestagecan.involverh.com.mx/',
      restPrefixChatBot:
        'https://an2se13cbj.execute-api.us-east-1.amazonaws.com/pre/chatbot-reclutador',
      chatBotToken:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMzI4ODU5LjkyMjk4NjN9.YSTvrAPQffY7IeStoWdaWczDkqz4KRpCmnxFKtbPMJY',
      firebase: {
        apiKey: 'AIzaSyDts4tJyQtJA7PRmyUXYcLr12m6nvuc5Gw',
        authDomain: 'involvedemo.firebaseapp.com',
        projectId: 'involvedemo',
        storageBucket: 'involvedemo.appspot.com',
        messagingSenderId: '492023559670',
        appId: '1:492023559670:web:479c2fb00a90ebda4303d7',
      },
      VAPID_KEY:
        'BH8REQWtD8Jg00JJNw5SXdUiJxNt6l90uKXFwA7V5_5fFlZVzXrBacblExgMl_b2YiYSWMWlpNTQMaecr0BqjfA',
      isDevelopment: false,
      talkAppId: 'tqksAfwX',
      stripePublicKey: {
        MEX: 'pk_test_51MTqAzJCQT8rhUScfg4lDFpqtnpQ0YCPw54byaELUz3xB1rvjnWF2vYs5GRv74n7TRQ8Su9qJIeQSFTh76S6Se8w00HbVAHmsw',
        ESP: '',
      },
      apiUrl: {
        auth: 'https://umbreon.involverh.com/auth',
        vacancy: 'https://umbreon.involverh.com/vacancy',
        management: 'https://umbreon.involverh.com/management',
        user: 'https://umbreon.involverh.com/user',
        psychometric: 'https://umbreon.involverh.com/psychometric',
        files: 'https://umbreon.involverh.com/files',
        ia: 'https://umbreon.ia.involverh.com',
        iaAssistant: 'https://umbreon.ia.involverh.com/files',
      },
      userflow: {
        key: 'ct_rxxgrmljufguzhtf2kwoczzg2m',
        dontForgetToBillYourPurchaseId: 'a94c0e70-52ea-4714-82d4-6c931bc66914',
        billingReminderAfterLogin: 'eaace7cb-a7ff-4991-9571-d703e2125ff9',
      },
    },
    PROD: {
      production: true,
      restPrefix:
        'https://2as657ryeb.execute-api.us-east-1.amazonaws.com/pro/involve',
      restPrefixPay:
        'https://2as657ryeb.execute-api.us-east-1.amazonaws.com/pro/pay',
      restPsychometric:
        'https://p08ea3ctx2.execute-api.us-east-1.amazonaws.com/pro',
      jobPool: 'https://www.involverh.com/',
      staffPanelUrl: 'https://staffpanel.involverh.com/',
      candidateUrl: 'https://talent.involverh.com/',
      restPrefixChatBot:
        'https://an2se13cbj.execute-api.us-east-1.amazonaws.com/pro/chatbot-reclutador',
      chatBotToken:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMzI4OTA3LjEwODU2MDN9.XwLZFnCX56aX8FgEhPgKF0R1i9k35ClRJ4Ys0lq1gdo',
      firebase: {
        apiKey: 'AIzaSyDts4tJyQtJA7PRmyUXYcLr12m6nvuc5Gw',
        authDomain: 'involvedemo.firebaseapp.com',
        projectId: 'involvedemo',
        storageBucket: 'involvedemo.appspot.com',
        messagingSenderId: '492023559670',
        appId: '1:492023559670:web:479c2fb00a90ebda4303d7',
      },
      VAPID_KEY:
        'BH8REQWtD8Jg00JJNw5SXdUiJxNt6l90uKXFwA7V5_5fFlZVzXrBacblExgMl_b2YiYSWMWlpNTQMaecr0BqjfA',
      isDevelopment: true,
      talkAppId: 'EgcCQfVU',
      stripePublicKey: {
        MEX: 'pk_live_51MTqAzJCQT8rhUScNR6lkOpZx114eGxloLM5eMWqBE7CFGMhlroF8e9BwXcoHYbGoUbZdEAcqjekRYuXfywEmN8C00WCeXOM5N',
        ESP: 'pk_live_51NW129KXFrczkLHv12RniMFn61J7MVsX83Y8d1trq2wAkFpQWg7EOYDRS4ORdGacQVoiJCUVvoxUg8f2krOphB93009usxCSaC',
      },
      apiUrl: {
        auth: 'https://prod.micros.involverh.com.mx/auth',
        vacancy: 'https://prod.micros.involverh.com.mx/vacancy',
        management: 'https://prod.micros.involverh.com.mx/management',
        user: 'https://prod.micros.involverh.com.mx/user',
        psychometric: 'https://prod.micros.involverh.com.mx/psychometric',
        files: 'https://prod.micros.involverh.com.mx/files',
        ia: 'https://amq3qsjt64.execute-api.us-east-1.amazonaws.com/prod',
        iaAssistant: 'https://assistant.prod.ia.involverh.com.mx',
      },
      userflow: {
        key: 'ct_gzcsungo2vdrfcgdjx5lbtmmcy',
        dontForgetToBillYourPurchaseId: 'a94c0e70-52ea-4714-82d4-6c931bc66914',
        billingReminderAfterLogin: 'eaace7cb-a7ff-4991-9571-d703e2125ff9',
      },
    },
  },
};

export const KEYSYSTEM_SETTINGS = SETTINGS[currentKeySystem] || SETTINGS['MEX'];

interface GlobalSettings {
  [key: string]: KeySystemSettings;
}

interface KeySystemSettings {
  // An identifier to know wich 'organizational unit' are we
  // pointing to. Currently it can be 'México' or 'España' but
  // in the future we'll have a lot of them.
  keySystemIdentifier: string;

  // In vacancy creation, step 1, benefits are shown in two columns
  // with similar height. Based on the catlog's length, the value should be
  // something like: ( catalog.length + 2 ) / 2
  maxBenefitsPerColumnInDesktopView: number;

  // For MEX, there is only one option ("monthly"). For ESP the user can select
  // the periodicty for salary
  hasMultipleSalaryPeriodicity: boolean;

  // In vacancy creation, step 3, we ask for education information.
  // From a specific education level we ask for the gotten degree and
  // institution name. These two are meant to be optional strings
  minEducationLevelToShowAdditionalFields: number;

  // Similar to `minEducationLevelToShowAdditionalFields` but turns the
  // institution field a catalog.
  // MUST be higher or equal than `minEducationLevelToShowAdditionalFields`
  minEducationLevelToAutocompleteInstitution: number;

  // For ESP, there is only on sector and many associated industries,
  // so this field is not visible for this key system
  isSectorVisible: boolean;

  // LowerEducationLevel: From 1 to (minEducationLevelToShowAdditionalFields - 1)
  // A list of education status available based on selected education level
  educationStatusInLowerEducation: number[];

  // HigherEducationLevel: From minEducationLevelToShowAdditionalFields
  // A list of education status available based on selected education level
  educationStatusInHigherEducation: number[];

  //                             Trunco  En curso    Pasante Egresado    Titulado
  // Edu básica                  x       x                   x
  // Bachilletato                x       x                   x
  // Bachillerato técnico        x       x           x       x           x
  // TSU                         x       x           x       x           x
  // Licenciatura                x       x           x       x           x
  // Especialidad                x       x           x       x           x
  // Maestría                    x       x           x       x           x

  //                             Sin finalizar   En curso    Graduado    Titulado
  // Educación infantil          x                           x
  // Educación primaria          x                           x
  // ESO                         x                           x
  // Bachillerato                x                           x
  // FP                          x               x                       x
  // Carrera universitaria       x               x                       x
  // Másteres y posgrados        x               x                       x
  // Doctorado                   x               x                       x

  // In the postulants view and candidate view, a tooltip
  // is shown in order to show the normalized salary according
  // to the vacancy periodicity
  showsCandidateSalaryNormalized: boolean;

  // Flag to enable chat gpt functionality
  isChatGptEnabled: boolean;

  // A regex to validate a string as a phone number
  phoneFormaterPattern: RegExp;

  // A regex to format a string as a phone number
  phoneValidatorPattern: RegExp;

  // Minimum amount to enable a purchase. Based on
  // stripe conditions
  minimumAmount: number;

  // Email to write for custom plans on Add Credits sections
  salesEmail: string;

  // A flag to enable a maintenance message through the application
  isInMaintenance: boolean;

  // Locale for moment library
  momentLocale: string;

  // phone lada for contry
  phoneLada: string;

  // phone required
  phoneRequired: boolean;

  // Terms and conditions url
  termsAndConditionsUrl: string;

  // Privacy notice url
  privacyNoticeUrl: string;

  // Cookies url
  cookiesUrl: string;

  //
  tips: string;

  mainLanding: string;

  countryGroup: any;

  currencyList: any;

  paymentList: any;

  chatGptEnabled: boolean;
}

export interface IEnvironment {
  production: boolean;
  restPrefix: string;
  restPrefixPay: string;
  restPsychometric: string;
  jobPool: string;
  staffPanelUrl: string;
  candidateUrl: string;
  restPrefixChatBot: string;
  chatBotToken: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  VAPID_KEY: string;
  isDevelopment: boolean;
  talkAppId: string;
  stripePublicKey: { MEX: string; ESP: string };
  apiUrl: {
    vacancy: string;
    management: string;
    user: string;
    psychometric: string;
    files: string;
    ia: string;
    iaAssistant: string;
    auth: string;
  };
  userflow: {
    key: string;
    dontForgetToBillYourPurchaseId: string;
    billingReminderAfterLogin: string;
  };
}

export interface IEnvironmentList {
  MEX: {
    DEV: IEnvironment;
    QA: IEnvironment;
    STAGE: IEnvironment;
    PROD: IEnvironment;
  };
}
